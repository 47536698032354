<template>
  <div class="news" v-if="!isProcessing">
    <div v-for="item in items" :key="item.title">
      <news-menu :item="item" />
    </div>
  </div>
</template>

<script>
import NewsMenu from '@/components/common/menu.vue'

export default {
  name: 'news',
  components: { NewsMenu },
  data () {
    return {
      items: [
        { title: 'ニュースの追加', content: '新しいニュースを追加できる', to: 'news_add' },
        { title: 'ニュースの編集', content: 'ニュースの編集、削除ができる', to: 'news_list' }
      ]
    }
  },
  mounted () {
    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.news {
  margin-top: $header_height;
}
</style>
